<div class="row">
    <div class="col-md-12">
      <button type="button" mdbBtn color="primary" class="rounded" [disabled]="this.isDataValidForViewContact()" (click)="viewContactsModal()">View Contacts</button>
      <button type="button" mdbBtn color="primary" class="rounded" [disabled]="this.isDataValidForPreviewEmail()" (click)="previewEmail()">Preview Email</button>
    </div>
</div>

<!-- Show Contact Preview Modal -->
<div mdbModal [config]="{backdrop: 'static'}" #contactPreviewModal class="modal fade"
tabindex="-1" role="dialog" aria-labelledby="contactPreviewModalLabel" aria-hidden="true" (opened)="onOpened($event)">
  <div class="modal-dialog modal-fluid modal-dialog-scrollable-broken" role="document">
    <div class="modal-content rounded">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="contactPreviewModalLabel">Contacts Preview</h4>
      </div>
      <div class="modal-body">
        <div *ngIf="_tableHasRows" class="mat-table-container">
            <ngx-datatable
                class="contact-preview-table"
                style="height: calc(85vh - 200px); overflow-y:auto"
                [columnMode]="_columnMode.force"
                [headerHeight]="40"
                [footerHeight]="0"
                [rowHeight]="50"
                [scrollbarV]="false"
                [scrollbarH]="true"
                [rows]="_rows"
                [externalPaging]="true"
                [count]="_pageLimit"
                [offset]="_pageOffset"
                [limit]="_pageLimit"
                (sort)="onSort($event)">

                <ngx-datatable-column
                *ngFor="let col of _columns"
                [width]="150"
                [headerClass]="'lists-table-headers'"
                [name]="col.name"
                [prop]="col.prop">
                </ngx-datatable-column>
            </ngx-datatable>
            <mat-paginator
                    [hidden]="!_tableHasRows"
                    [length]="_pageCount"
                    [pageSize]="_pageLimit"
                    [pageSizeOptions]="_pageSizeOptions"
                    [pageIndex]="_pageOffset"
                    (page)="paginateTable($event)"
                    class="mat-paginator">
            </mat-paginator>
        </div>
        <div *ngIf="!_tableHasRows">
            <span class="label-no-data ml-2">No data to display.</span>
          </div>
      </div>
      <div class="modal-footer">
        <button *ngIf="!_exporting" [disabled]="!_tableHasRows" mdbBtn color="secondary" outline="true" rounded="true"
            size="sm" type="button" class="px-2 header-button mr-0" mdbWavesEffect (click)="export()">
            <mdb-icon fas size="lg" class="" icon="file-export"></mdb-icon>
          </button>
          <button *ngIf="_exporting" disabled mdbBtn color="secondary" outline="true" rounded="true" size="sm" type="button"
            class="px-2 header-button mr-0" mdbWavesEffect>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
          (click)="closeModal()" mdbWavesEffect>Close</button>
      </div>
    </div>
  </div>
</div>
<!-- Show Contact Preview Modal -->

