import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ModalDirective } from 'ng-uikit-pro-standard';
import SpinnerModalDisplay from 'src/app/views/shared/constants/modal-constants';
import { DataTableColumn } from 'src/app/_models/messaging/datatable-column';
import { PaginationDataService } from 'src/app/_services/tables/pagination-data/pagination-data.service';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { HelperService } from 'src/app/_services/system/helpers/helper.service';
import { SearchContactDtoRequest } from 'src/app/_models/messaging/lists-and-contacts/contacts/search-contact-dto-request';
import { ContactService } from 'src/app/_services/messaging/lists-and-contacts/contacts/contact.service';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { NotificationService } from 'src/app/_services/notification.service';
import { DatePipe } from '@angular/common';
import { SelectListOption } from 'src/app/_models/system/select-list-option';
import { ListModel } from 'src/app/_models/messaging/lists-and-contacts/lists/list-model';
import { ListsService } from 'src/app/_services/messaging/lists-and-contacts/lists/lists.service';
import { ListModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/list-model.adapter';
import { QueryBuilderStorageService } from 'src/app/_services/query-builder-filters/query-builder-storage.service';
import { LoadingSpinnerService } from '../../../../../_services/loading-spinner/loading-spinner.service';

@Component({
  selector: 'app-preview-data-message',
  templateUrl: './preview-data-message.component.html',
  styleUrls: ['./preview-data-message.component.scss']
})
export class PreviewDataMessageComponent implements OnInit {

  //Modal and exteral Inputs from Parent Component
  @ViewChild('contactPreviewModal', { static: true }) contactPreviewModal: ModalDirective;
  @Input() parentStepForm: UntypedFormGroup;
  @Input() listFolder: number;
  @Input() listID: number;
  @Input() filterContactRules: string = '';
  @Input() messageID: number;

  //Variables for the table
  _tableHasRows = false;
  _allColumns: DataTableColumn[] = [];
  _columns: DataTableColumn[] = [];
  _columnMode = ColumnMode;
  _rows: { [prop: string]: any }[] = [];

  //Default column sorting value variables
  _sortColumn: string = 'UpdatedDate';
  _sortDirection: string = 'Descending';

  //Pagination
  _pageSizeOptions: number[] = [10, 25, 50, 100];
  _currentPage = 1;
  _pageCount = 0;
  _pageLimit = 10;
  _pageOffset = 0;
  _pageTitle = 'Contacts Preview';

  //Timeout variable for spinner modal
  _timeout: number;

  //Exports
  _exportRows: { [prop: string]: any }[] = [];
  _exporting = false;
  _listsSelectListOptions: SelectListOption[] = [];

  //General Page Vars
  _isEmailMessageType = true;

  constructor(
    private paginationDataService: PaginationDataService,
    private contactService: ContactService,
    private listsService: ListsService,
    private listModelAdapter: ListModelAdapter,
    private helperService: HelperService,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    private queryBuilderStorageService: QueryBuilderStorageService,
    private loadingSpinnerService: LoadingSpinnerService,
  ) { }

  ngOnInit() {
    //Pagination for Contact View
    this.paginationDataService.GetChildModelPaginationDataFromLocalStorage();

    if (this.paginationDataService.childModelPaginationData.lastPage === this._pageTitle && this.paginationDataService.childModelPaginationData.page) {
      this._currentPage = this.paginationDataService.childModelPaginationData.page;
      this._pageLimit = this.paginationDataService.childModelPaginationData.pageLimit;
      this._sortColumn = this.paginationDataService.childModelPaginationData.sortByColumn;
      this._sortDirection = this.paginationDataService.childModelPaginationData.sortByDirection;
    }

    if (this.paginationDataService.childModelPaginationData.lastPage != this._pageTitle) {
      this.paginationDataService.SetChildModelPaginationDataToLocalStorage(this._currentPage, this._pageLimit, this._sortColumn, this._sortDirection, this._pageTitle);
    }

    this.onParentalControlChanges();
  }

  // Subscribe to any parent control changes
  onParentalControlChanges() {
    var contactFilterCriteriaFC = this.parentStepForm.controls.triggerFormGroup.get('contactFilterCriteria');

    //Check to see if the message type is Email or SMS and disable based on button
    var sendMessageType = this.parentStepForm.controls.actionsToPerformFormGroup.get('sendContactMessage').get('sendMessageType').value;
    if (sendMessageType && (sendMessageType == 1 || sendMessageType == 3)) {
      this._isEmailMessageType = true;
    } else {
      this._isEmailMessageType = false;
    }

    //Get current filter contact rules value
    this.filterContactRules = contactFilterCriteriaFC.value != null ? contactFilterCriteriaFC.value : '';

    //Subscribe and Populate List for Contacts Export and Get Contacts for Preview
    this.parentStepForm.controls.actionRelatesFormGroup.get('list').valueChanges.subscribe(val => {
      if (this.listID) {
        this.listID = val;
      }
    });

    //Update List if Sql filter has changed
    contactFilterCriteriaFC.valueChanges.subscribe(val => {
      if (this.listID) {
        this.filterContactRules = val;
      }
    });

    //Disable Preview Email button if sendMessageType is SMS
    this.parentStepForm.controls.actionsToPerformFormGroup.get('sendContactMessage').get('sendMessageType').valueChanges.subscribe(val => {
      if (val == 1 || val == 3) this._isEmailMessageType = true;
      else this._isEmailMessageType = false;
    });

    //Subscribe to Message ID value to ensure right email is being previewed
    this.parentStepForm.controls.actionsToPerformFormGroup.get('sendContactMessage').get('message').valueChanges.subscribe(val => this.messageID = val);
  }

  // Preview Email in New Tab
  previewEmail() {
    //Save Sql to local storage
    this.queryBuilderStorageService.SetSQLFilterToLocalStorage(this.filterContactRules);

    //Ensure message is selected before showing them the preview page and open url in new tab
    if (!this.messageID) this.notificationService.showWarning("Please ensure you have selected an email");
    else window.open(`${window.location.protocol}//${window.location.host}/messages/email-preview/${this.messageID}/${this.listFolder}/${this.listID}`, "_blank");
  }

  // Contacts Modal & Table Functions

  viewContactsModal() {
    //Populate List for Contacts Export and Get Contacts for Preview First Time
    if (!this.listID) this.notificationService.showWarning("You havent selected a List");
    else {
      this.contactPreviewModal.show();
    }
  }

  //Upon Modal open, populate List and Get Contacts
  onOpened(event: any) {
    this.populateList();
    this.getContacts();
  }

  // Get List by List ID - Primarily used for export
  populateList() {
    this.listsService.getListById(this.listID).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        const listModels = executionResultDto.data.map((listModel: ListModel) => this.listModelAdapter.adapt(listModel));
        this._listsSelectListOptions = listModels.map((listModel: ListModel) => new SelectListOption(listModel.ListID, listModel.ListName));
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  // optional variables that allow dynamic sorting as data is retrieved from the database
  // rather than all stored client side
  getContacts(pageInfo?: any, sortDirection?: string, sortColumn?: string) {
    this.loadingSpinnerService.loading();

    if (sortDirection !== undefined) this._sortDirection = sortDirection;
    if (sortColumn !== undefined) this._sortColumn = sortColumn;
    var pageNumber = pageInfo ? pageInfo : 1;

    if (pageInfo) this.paginationDataService.childModelPaginationData.page = pageNumber;
    else if (this.paginationDataService.childModelPaginationData.page) pageNumber = this.paginationDataService.childModelPaginationData.page;

    this._currentPage = pageNumber;

    const pagerDtoRequest = new PagerDtoRequest({
      page: this._currentPage,
      pageSize: this._pageLimit,
      sortColumn: this._sortColumn,
      sortDirection: this._sortDirection
    });

    const consentfilter = !this._isEmailMessageType ? 'ISNULL(Consent_Email, 1) = 1 AND Bounced = 0' : 'ISNULL(Consent_SMS, 1) = 1';
    var listFilterContactRules = '';

     if (this.filterContactRules.indexOf(consentfilter) === -1)  {
       listFilterContactRules = this.filterContactRules.length === 0 ? consentfilter : this.filterContactRules + ' AND (' + consentfilter + ')'
	   } 

    const getContactsPagerDtoRequest = new SearchContactDtoRequest({
      pagerDtoRequest: pagerDtoRequest,
      listId: this.listID,
      contactFilter: listFilterContactRules
    });

    this.paginationDataService.SetChildModelPaginationDataToLocalStorage(pageNumber, this._pageLimit, this._sortColumn, this._sortDirection, this._pageTitle);

    this.contactService.getContacts(
        getContactsPagerDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this._pageCount = executionResultDto.data.Total;
          this._pageOffset = pageNumber - 1;

          this._tableHasRows = this._pageCount > 0;

          this._allColumns = this._columns = this._rows = [];

          if (!this._tableHasRows) {
            this.loadingSpinnerService.stopLoading();
            return;
          }

          this._allColumns = executionResultDto.data.Columns.map(column =>
            new DataTableColumn({
              prop: column.Name,
              name: column.DisplayName.replace(/([a-z])([A-Z])/g, '$1 $2'),
              visible: true
          }));

          this._columns = this._allColumns;

          this._rows = this.getRows(executionResultDto);

        } else {
          this.loadingSpinnerService.stopLoading();
          this.notificationService.showError(executionResultDto.message);
        }

        this.loadingSpinnerService.stopLoading();
    });
  }

  // Export Contacts Preview
  export() {

    const pagerDtoRequest = new PagerDtoRequest({
      page: 1,
      pageSize: 1000000,
      sortColumn: 'UpdatedDate',
      sortDirection: 'DESC'
    });

    const getContactsPagerDtoRequest = new SearchContactDtoRequest({
      pagerDtoRequest: pagerDtoRequest,
      listId: this.listID,
      contactFilter: this.filterContactRules
    });

    this._exporting = true;

    this.contactService.getContacts(getContactsPagerDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {

        this._exportRows = this.getRowsForExport(executionResultDto);

        const listName = this._listsSelectListOptions.find(x => x.value === this.listID).label;
        const today = new Date();
        const fileName = `${listName}_${today.getDate()}${today.getMonth() + 1}${today.getFullYear()}`;

        this.helperService.exportToCSV(fileName, this._exportRows);

      } else {
        this.notificationService.showError(executionResultDto.message);
        return;
      }
      this._exporting = false;
    });
  }

  // Get Rows to display Contacts
  getRows(executionResultDto: ExecutionResultDto) {
    let rows: { [prop: string]: any }[] = [];
    let dateTimeColumns = executionResultDto.data.Columns.filter(element => element.FieldTypeID == 10);
    let dateColumns = executionResultDto.data.Columns.filter(element => element.FieldTypeID == 8);

    rows = executionResultDto.data.Records.map(record => {
      const row: { [prop: string]: any } = {};
      Object.keys(record).map(key => {
        if (record[key] !== null && record[key] !== undefined) {
          if (dateTimeColumns.some(val => val.Name == key)) {
            const date = new Date(record[key]);
            row[key] = this.datePipe.transform(date, 'dd/MM/yyyy HH:mm:ss');
          } else if (dateColumns.some(val => val.Name == key)) {
            const date = new Date(record[key]);
            row[key] = this.datePipe.transform(date, 'dd/MM/yyyy');
          } else if (record[key].toString().toLowerCase() === 'true') {
            row[key] = 'Yes';
          } else if (record[key].toString().toLowerCase() === 'false') {
            row[key] = 'No';
          } else {
            row[key] = record[key];
          }
        }
      });
      return row;
    });
    return rows;
  }

  // Get Rows for Export
  getRowsForExport(executionResultDto: ExecutionResultDto) {
    let rows: { [prop: string]: any }[] = [];
    let dateTimeColumns = executionResultDto.data.Columns.filter(element => element.FieldTypeID == 10);
    let dateColumns = executionResultDto.data.Columns.filter(element => element.FieldTypeID == 8);

    rows = executionResultDto.data.Records.map(record => {
      const row: { [prop: string]: any } = {};
      Object.keys(record).map(key => {
        var propColumn = this._columns.find(col => col.prop == key && col.visible);
        if (propColumn) {
          var friendlyName = propColumn.name.replace(/([a-z])([A-Z])/g, '$1 $2');
          if (this._columns.some(col => col.name === friendlyName)) {
            if (friendlyName.indexOf(',') != -1) friendlyName = friendlyName.replace(/,/g, ''); //remove commas from column names if they are any
            if (record[key] !== null && record[key] !== undefined) {
              if (dateTimeColumns.some(val => val.Name == key)) {
                const date = new Date(record[key]);
                row[friendlyName] = this.datePipe.transform(date, 'dd/MM/yyyy HH:mm:ss');
              } else if (dateColumns.some(val => val.Name == key)) {
                const date = new Date(record[key]);
                row[friendlyName] = this.datePipe.transform(date, 'dd/MM/yyyy');
              } else if (record[key].toString().toLowerCase() === 'true') {
                row[friendlyName] = 'Yes';
              } else if (record[key].toString().toLowerCase() === 'false') {
                row[friendlyName] = 'No';
              } else if (this.isValidContactNumber(record[key])) {
                row[friendlyName] = `=\"${record[key]}\"`;
              } else {
                row[friendlyName] = record[key];
              }
          } else {
            row[friendlyName] = record[key];
          }
        }
      }
    });
    return row;
  });
  return rows;
  }

  // add ability to dynamically sort data retrieved from the database by providing the direction and column being sorted
  // we also need to ensure that the captured directions are formatted to work with the API and SQL script
  onSort($event) {
    var direction: string;
    if ($event.sorts[0].dir === "desc") {
      direction = "Descending";
    }
    else {
      direction = "Ascending";
    }
    this.getContacts(undefined, direction, $event.sorts[0].prop);
  }

  // give table pagination functionality
  // as the sql behind the data does not do this for us
  paginateTable(event?: any) {

    if (event !== undefined) {
      this._pageLimit = event.pageSize;
      this._currentPage = + event.pageIndex + 1;
      this._pageOffset = + event.pageIndex;
    }

    this.paginationDataService.SetChildModelPaginationDataToLocalStorage(this._currentPage, this._pageLimit, this._sortColumn, this._sortDirection, this._pageTitle);
    this.getContacts(this._currentPage);
  }

  isValidContactNumber(value) {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value);
  }

  closeModal() {
    this.contactPreviewModal.ngOnDestroy();
  }

  isDataValidForViewContact() {
    return this.parentStepForm.controls.actionRelatesFormGroup.invalid || this.parentStepForm.controls.triggerFormGroup.invalid ||
           this.parentStepForm.controls.actionsToPerformFormGroup.invalid || this.parentStepForm.controls.timingFormGroup.invalid;
  }

  isDataValidForPreviewEmail() {
    return this.parentStepForm.controls.actionRelatesFormGroup.invalid || this.parentStepForm.controls.triggerFormGroup.invalid ||
           this.parentStepForm.controls.actionsToPerformFormGroup.invalid || this.parentStepForm.controls.timingFormGroup.invalid || this._isEmailMessageType == false;
  }
}
