import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { FormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { FolderDto } from 'src/app/_models/messaging/messages/folder-dto';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';
import { MessageService } from 'src/app/_services/messaging/messages/message.service';
import { LightweightMessageModel } from 'src/app/_models/messaging/messages/lightweight-message-model';
import { LightweightMessageModelAdapter } from 'src/app/_models/messaging/messages/lightweight-message-model.adapter';
import { CreateEditTriggerComponent } from '../create-edit-trigger.component';
import { SelectContactsComponent } from './select-contacts/select-contacts.component';
import { ChangeModel } from 'src/app/_models/messaging/automation/triggers/trigger-params/update-contact-action-info-models/change-model';
import { UpdateContactValuesComponent } from './update-contact-values/update-contact-values.component';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { ChangeModelAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-params/update-contact-action-info-models/change-model.adapter';
import { TriggersService } from 'src/app/_services/messaging/automation/triggers/triggers.service';
import { EmailMessagesService } from 'src/app/_services/messaging/messages/email-messages/email-messages.service';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { NotificationService } from 'src/app/_services/notification.service';
import { SmsMessageService } from 'src/app/_services/messaging/messages/sms-messages/sms-message.service';
import { ApiIntegrationModel } from 'src/app/_models/messaging/automation/api-integration/api-integration-model';
import { ApiIntegrationModelAdapter } from 'src/app/_models/messaging/automation/api-integration/api-integration-model.adapter';
import { AvailableDMSDto } from 'src/app/_models/admin/clients/available-dms-dto';
import { AvailableDMSDtoAdapter } from 'src/app/_models/admin/clients/available-dms-dto.adapter';
import { ClientManagementService } from 'src/app/_services/admin/clients/client-management.service';
import { ApiIntegrationEndpointModel } from 'src/app/_models/messaging/automation/api-integration/api-integration-endpoint-model';
import { ApiIntegrationEndpointFieldsMappingModel } from 'src/app/_models/messaging/automation/api-integration/api-integration-endpoint-fields-mapping-model';
import { LightListFieldDto } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-field-dto';
import { LightListFieldDtoAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-field-dto.adapter';
import { SendViaApiFieldMapping } from 'src/app/_models/messaging/automation/triggers/trigger-params/send-via-api-field-mapping';
import { ClientDto } from 'src/app/_models/admin/clients/client-model';
import { LocalStorageService } from 'src/app/_services/system/localStorage/localStorage.service';
import { HelperService } from 'src/app/_services/system/helpers/helper.service';

@Component({
  selector: 'app-actions-to-perform',
  templateUrl: './actions-to-perform.component.html',
  styleUrls: ['./actions-to-perform.component.scss'],
  animations: [
    trigger(
      'slide',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0, }),
            animate('.5s ease-out',
              style({ height: '*', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: '*', opacity: 1 }),
            animate('.5s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class ActionsToPerformComponent implements OnInit {
  @Input() actionsToPerformFormGroup: UntypedFormGroup;
  @Input() actionRelatesFormGroup: UntypedFormGroup;
  @Input() triggerFormGroup: UntypedFormGroup;

  @ViewChild('selectContactsComponent', { static: true }) selectContactsComponent: SelectContactsComponent;
  @ViewChild('selectContactsUploadComponent', { static: true }) selectContactsUploadComponent: SelectContactsComponent;
  @ViewChild('updateContactValuesComponent', { static: true }) updateContactValuesComponent: UpdateContactValuesComponent;
  @ViewChild('updateContactValueModal', { static: true }) updateContactValueModal: ModalDirective;
  @ViewChild('messagePreviewModal', { static: true }) _messagePreviewModal: ModalDirective;

  sendContactMessage: any;
  notifyContactMessage: any;
  notifyContactUploadMessage: any;
  updateValues: any;
  sendViaApiForm: any;


  _showSendForm: boolean = false;
  _showNotifyForm: boolean = false;
  _showNotifyUploadForm: boolean = false;
  _showUpdateValuesForm: boolean = false;
  _showSendViaApiForm: boolean = false;
  _showSendViaApiSwitch: boolean = false;

  _messageFolderDropdown: Array<any> = [];
  _triggerApiDropdown: Array<any> = [];
  _currentSendMessageType: MessageTypeEnum = MessageTypeEnum.email;
  _currentNotifyMessageType: MessageTypeEnum = MessageTypeEnum.email;

  _sendMessageDropdown: Array<any> = [];
  _notifyMessageDropdown: Array<any> = [];
  _availableSystemDropdown: Array<any> = [];
  _availableLocationsDropdown: Array<any> = [];
  _apiIntegrationEndpointFields: Array<any> = [];
  _apiSectionDropdown: Array<any> = [];
  _availableSystems: Array<AvailableDMSDto>;
  _listID: number = 0;
  _listFields: Array<LightListFieldDto>;
  _listFieldsDropdown: Array<any> = [];
  _isPlusButtonEnabled = false;

  selectedApiSectionName: string;
  selectedSchemaId: number;
  selectedLocationName: number;
  selectedTriggerApi: string;
  selectedContacts: Array<any> = [];
  selectedContactIds: Array<number> = [];
  updateContactValues: Array<ChangeModel> = [];
  apiIntegrations: Array<ApiIntegrationModel> = [];
  apiIntegrationEndpointFieldsMappings: Array<ApiIntegrationEndpointFieldsMappingModel> = [];
  sendViaApiFieldMappings: Array<SendViaApiFieldMapping> = [];
  currentClientId: string;
  selectedContactsUpload: Array<any> = [];
  selectedContactIdsUpload: Array<number> = [];
  _selectedTriggerType = -1;

  _readableContactValues: Array<any> = [];

  _messagePreviewHtml = '';
  _sendContactsSelectedMessageId = 0;
  _notifyContactsSelectedMessageId = 0;

  //Default Message Type to Email
  _messageTypeNum = 1;

  _smsMessageContent = '';
  _smsMessageContentDate = '';
  _showSendApiFieldsMapping = false;
  _showSendApiSystem = false;
  _showSendApiLocation = false;
  _showNotifyContactsUpload = false;

  _isMDUser = false;

  constructor(
    private messageService: MessageService,
    private lightweightMessageModelAdapter: LightweightMessageModelAdapter,
    public createEditTriggerComponent: CreateEditTriggerComponent,
    private changeModelAdapter: ChangeModelAdapter,
    private apiIntegrationModelAdapter: ApiIntegrationModelAdapter,
    public triggersService: TriggersService,
    public emailMessageService: EmailMessagesService,
    public smsMessageService: SmsMessageService,
    public notificationService: NotificationService,
    private availableDMSDtoAdapter: AvailableDMSDtoAdapter,
    public clientManagementService: ClientManagementService,
    private lightListFieldDtoAdapter: LightListFieldDtoAdapter,
    private localStorageService: LocalStorageService,
    private helperService: HelperService
  ) { }

  ngOnInit() {
    this.currentClientId = this.localStorageService.getCurrentClientId;
    this.sendContactMessage = this.actionsToPerformFormGroup.controls.sendContactMessage;
    this.notifyContactMessage = this.actionsToPerformFormGroup.controls.notifyContactMessage;
    this.updateValues = this.actionsToPerformFormGroup.controls.updateValues;
    this.sendViaApiForm = this.actionsToPerformFormGroup.controls.sendViaApiForm;
    this.notifyContactUploadMessage = this.actionsToPerformFormGroup.controls.notifyContactUploadMessage;
    this.onChanges();
    this._showSendViaApiSwitch = this.localStorageService.getEmail.includes("@marketing.delivery")
      || this.localStorageService.getEmail.includes("@marketingdelivery.com");     
    this.populateTriggerAPISection();

    this._isMDUser = this.helperService.isLoggedInUserMDUser();
  }

  onChanges() {
    this.actionsToPerformFormGroup.get('sendContactMessageToggle').valueChanges.subscribe(val => {
      this._showSendForm = val;
      this.addRemoveValidation();
    });
    
    this.actionsToPerformFormGroup.get('notifyContactMessageToggle').valueChanges.subscribe(val => {
      this._showNotifyForm = val;
      this.addRemoveValidation();
    });

    this.actionsToPerformFormGroup.get('notifyContactUploadToggle').valueChanges.subscribe(val => {
      this._showNotifyUploadForm = val;
      this.addRemoveValidation();
    });

    this.actionsToPerformFormGroup.get('updateValuesToggle').valueChanges.subscribe(val => {
      this._showUpdateValuesForm = val;
      this.addRemoveValidation();
    });

    this.actionsToPerformFormGroup.get('sendViaApiToggle').valueChanges.subscribe(val => {
      this._showSendViaApiForm = val;
      this.addRemoveValidation();
    });

    this.sendContactMessage.get('sendMessageType').valueChanges.subscribe(val => {
      this._currentSendMessageType = val;
      if (this.sendContactMessage.get('messageFolder').value != null) {
        this.GetLightMessages(this.sendContactMessage.get('messageFolder').value, 'send');
      }
      this.sendContactMessage.get('message').setValue(null);
    });

    this.notifyContactMessage.get('messageType').valueChanges.subscribe(val => {
      this._currentNotifyMessageType = val;
      if (this.notifyContactMessage.get('messageFolder').value != null) {
        this.GetLightMessages(this.notifyContactMessage.get('messageFolder').value, 'notify');
      }
      this.notifyContactMessage.get('message').setValue(null);
    });

    this.sendContactMessage.get('messageFolder').valueChanges.subscribe(val => {
      this.GetLightMessages(val, 'send');
    });

    this.notifyContactMessage.get('messageFolder').valueChanges.subscribe(val => {
      this.GetLightMessages(val, 'notify');
    });

    this.actionRelatesFormGroup.get('list').valueChanges.subscribe(val => {
      if (val) {
        this.updateContactValuesComponent.getContactFields(val);
      }
    });

    this.triggersService.fields.valueChanges.subscribe(() => {
      this.showSelectedContactValues();
    });

    this.updateContactValueModal.closed.subscribe(() => {
      this.showSelectedContactValues();
      this.populateUpdateContactValues();
    });

    this.sendContactMessage.get('message').valueChanges.subscribe((value: any) => {
      this._sendContactsSelectedMessageId = value;
    });

    this.notifyContactMessage.get('message').valueChanges.subscribe((value: any) => {
      this._notifyContactsSelectedMessageId = value;
    });

    this.actionRelatesFormGroup.get('list').valueChanges.subscribe(val => {
      this._listID = this.actionRelatesFormGroup.get('list').value;
      this.getListFields();
      this.sendViaApiForm.get('listField').setValue(null);
      this.sendViaApiForm.get('apiIntegrationEndpoint').setValue(null);
      this.apiIntegrationEndpointFieldsMappings = [];
      this.populateApiIntegrationEndpointFieldsDropdown();
    });

    this.triggerFormGroup.get('trigger').valueChanges.subscribe(val => {
      this._selectedTriggerType = val;
      if (this._selectedTriggerType != 18) {
        this._currentSendMessageType = MessageTypeEnum.email;
      }
    });
  }

  populateTriggerAPISection() {
    this.clientManagementService.getClientAPIs(this.currentClientId)
      .subscribe(result => {
        this.apiIntegrations = this.apiIntegrationModelAdapter.adaptArray(result.data);
        if (this.apiIntegrations == null || this.apiIntegrations.length == 0){
          this._showSendViaApiSwitch = false;
          return;
        }
        this.selectedTriggerApi = this.sendViaApiForm.get('triggerApi').value;
        this.selectedApiSectionName = this.sendViaApiForm.get('apiSection').value;
        this.selectedLocationName = this.sendViaApiForm.get('location').value;
        this.selectedSchemaId = this.sendViaApiForm.get('system').value;
        this.apiIntegrations = this.apiIntegrationModelAdapter.adaptArray(result.data);

        this.apiIntegrations.forEach((apiIntegration: ApiIntegrationModel) => {
          this._triggerApiDropdown = [... this._triggerApiDropdown, {
            value: apiIntegration.name,
            label: apiIntegration.displayName
          }];
        });

        if (this.selectedApiSectionName != null) {
          this.populateApiSectionDropdown();
          this.getListFields();
          this._showSendApiFieldsMapping = true;
          this.populateApiIntegrationEndpointFieldsDropdown();
        }

        if (this.selectedSchemaId != null) {
          this.clientManagementService.getAvailableDMSs().subscribe(result => {
            this._availableSystems = this.availableDMSDtoAdapter.adaptArray(result.data);
            this._showSendApiSystem = true;            
            this.populateDMSDropdown();
          });
        }

        if (this.selectedLocationName != null) {
          this.populateLocationsDropdown();
          this._showSendApiLocation = true;
        }

        if (this.sendViaApiFieldMappings != null && this.sendViaApiFieldMappings.length > 0) {
          this.triggersService.getLightListFields(this._listID, true).subscribe(result => {
            this._listFields = this.lightListFieldDtoAdapter.adaptArray(result.data);
            this.populateApiIntegrationFieldsMappingFromSavedValues();
          });
        }
      });

    this.clientManagementService.getAvailableDMSs().subscribe(result => {
      this._availableSystems = this.availableDMSDtoAdapter.adaptArray(result.data);
    });

  }

  populateDMSDropdown() {
    this._availableSystemDropdown = [];
    var availableApiDMSIds = this.apiIntegrations
      .find(i => i.name == this.selectedTriggerApi)
      .clientParameters.map(p => p.schemaId);
    this._availableSystems.filter(as => availableApiDMSIds.includes(as.ID))
      .forEach((ETL: AvailableDMSDto) => {
        this._availableSystemDropdown = [... this._availableSystemDropdown, {
          value: ETL.ID,
          label: ETL.DMS + ' (' + ETL.Type + ')',
        }];
      });
  }

  populateLocationsDropdown() {
    this._availableLocationsDropdown = [];
    var availableApiLocations = this.apiIntegrations
      .find(i => i.name == this.selectedTriggerApi)
      .clientParameters.map(p => p.locationName);
    availableApiLocations = [...new Set(availableApiLocations)];
    availableApiLocations
      .forEach((l) => {
        this._availableLocationsDropdown = [... this._availableLocationsDropdown, {
          value: l,
          label: l,
        }];
      });
  }

  populateApiSectionDropdown() {
    this._apiSectionDropdown = [];
    this.apiIntegrations.find(i => i.name == this.selectedTriggerApi)
      .endpointNames
      .forEach((endpoint) => {
        this._apiSectionDropdown = [... this._apiSectionDropdown, {
          value: endpoint.name,
          label: endpoint.displayName,
        }];
      });
  }

  populateMessageFolderDropdown(messageFolders: Array<FolderDto>) {
    this._messageFolderDropdown = [];
    messageFolders.forEach((messageFolder: FolderDto) => {
      this._messageFolderDropdown = [... this._messageFolderDropdown, {
        value: messageFolder.id,
        label: messageFolder.name
      }];
    });
  }

  populateApiIntegrationEndpointFieldsDropdown() {
    if (this.apiIntegrations && this.selectedTriggerApi && this.selectedApiSectionName) {
      this._apiIntegrationEndpointFields = [];
      this.apiIntegrations.find(i => i.name == this.selectedTriggerApi)
        .endpointNames.find(i => i.name == this.selectedApiSectionName)
        .apiIntegrationEndpointFieldsMappingModel
        .forEach((endpoint) => {
          this._apiIntegrationEndpointFields = [... this._apiIntegrationEndpointFields, {
            value: endpoint.apiValue,
            label: endpoint.label.concat(endpoint.mandatory ? " *" : ""),
            mandatory: endpoint.mandatory,
          }];
          this._apiIntegrationEndpointFields.sort((a, b) => a.label < b.label ? - 1 : Number(a.label > b.label))
          .sort((a, b) => b.mandatory - a.mandatory);
        });

      this.addRemoveValidationApiFieldMapping();
    }

  }
  populateSavedApiIntegrationFieldsMapping(
    sendViaApiFieldMappings: SendViaApiFieldMapping[]) {
    this.sendViaApiFieldMappings = sendViaApiFieldMappings;
  }

  populateApiIntegrationFieldsMappingFromSavedValues() {
    this.apiIntegrationEndpointFieldsMappings = [];
    this.sendViaApiFieldMappings.forEach(element => {
      var apiIntegrationEndpointFieldsMappingModel = this.apiIntegrations
        .find(i => i.name == this.selectedTriggerApi)
        .endpointNames.find(i => i.name == this.selectedApiSectionName)
        .apiIntegrationEndpointFieldsMappingModel.find(e => e.apiValue == element.ApiFieldName);
        var voiceboxDisplayName = this._listFields
          .find(l => l.ColumnName == element.ContactFieldName)?.DisplayName;
        if(apiIntegrationEndpointFieldsMappingModel != null && voiceboxDisplayName != null){
          apiIntegrationEndpointFieldsMappingModel.voiceboxFieldName = element.ContactFieldName;
          apiIntegrationEndpointFieldsMappingModel.voiceboxDisplayName = voiceboxDisplayName;

          this.apiIntegrationEndpointFieldsMappings.push(apiIntegrationEndpointFieldsMappingModel);
      
          this._apiIntegrationEndpointFields = this._apiIntegrationEndpointFields.filter(a => a.value != element.ApiFieldName);
          this._listFieldsDropdown = this._listFieldsDropdown.filter(l => l.value != element.ContactFieldName);
        }
    });
    this._showSendApiFieldsMapping = true;
    this.addRemoveValidationApiFieldMapping();
    this.sendViaApiFieldMappings = [];
  }

  getListFields() {
    this._listID = this.actionRelatesFormGroup.get('list').value;
    if (this._listID) {
      this.triggersService.getLightListFields(this._listID, true).subscribe(result => {
        this._listFields = this.lightListFieldDtoAdapter.adaptArray(result.data);
        this.populateFieldDropdown();
      });
    }
  }

  populateFieldDropdown() {
    this._listFieldsDropdown = [];
    this._listFields.forEach((field: LightListFieldDto) => {
      this._listFieldsDropdown = [... this._listFieldsDropdown, {
        value: field.ColumnName,
        label: field.DisplayName,
      }];
      this._listFieldsDropdown.sort((a, b) => a.label < b.label ? - 1 : Number(a.label > b.label));
    });
  }

  notifyMappingFieldSelected() {
    if (this.sendViaApiForm.get('apiIntegrationEndpoint').value && this.sendViaApiForm.get('listField').value) {
      this._isPlusButtonEnabled = true;
      
    }
    else {
      this._isPlusButtonEnabled = false;
    }
    this.addRemoveValidationApiFieldMapping();
  }

  addApiFieldMapping() {
    if (this._isPlusButtonEnabled) {
      var apiValue = this.sendViaApiForm.get('apiIntegrationEndpoint').value;
      var vbField = this.sendViaApiForm.get('listField').value;
      var vbDisplayName = this._listFields.find(l => l.ColumnName == vbField).DisplayName;
      var apiIntegrationEndpointFieldsMappingModel = this.apiIntegrations
        .find(i => i.name == this.selectedTriggerApi)
        .endpointNames.find(i => i.name == this.selectedApiSectionName)
        .apiIntegrationEndpointFieldsMappingModel.find(e => e.apiValue == apiValue);
      apiIntegrationEndpointFieldsMappingModel.voiceboxFieldName = vbField;
      apiIntegrationEndpointFieldsMappingModel.voiceboxDisplayName = vbDisplayName;

      this.apiIntegrationEndpointFieldsMappings.push(apiIntegrationEndpointFieldsMappingModel);
      this._apiIntegrationEndpointFields = this._apiIntegrationEndpointFields.filter(a => a.value != apiValue);
      this._listFieldsDropdown = this._listFieldsDropdown.filter(l => l.value != vbField);
      this._isPlusButtonEnabled = false;
      this.sendViaApiForm.get('listField').setValue(null);
      this.sendViaApiForm.get('apiIntegrationEndpoint').setValue(null);
      this.addRemoveValidationApiFieldMapping();
    }

  }

  removeApiFieldMapping(apiValue: string) {
    var apiIntegrationEndpointFieldsMappingModel = this.apiIntegrationEndpointFieldsMappings.find(e => e.apiValue == apiValue);

    this.apiIntegrationEndpointFieldsMappings = this.apiIntegrationEndpointFieldsMappings.filter(a => a.apiValue != apiValue);
    this._apiIntegrationEndpointFields = [... this._apiIntegrationEndpointFields, {
      value: apiIntegrationEndpointFieldsMappingModel.apiValue,
      label: apiIntegrationEndpointFieldsMappingModel.label.concat(apiIntegrationEndpointFieldsMappingModel.mandatory ? " *" : ""),
      mandatory: apiIntegrationEndpointFieldsMappingModel.mandatory
    }];
    this._apiIntegrationEndpointFields.sort((a, b) => a.label < b.label ? - 1 : Number(a.label > b.label))
    .sort((a, b) => b.mandatory - a.mandatory);;
    this._listFieldsDropdown = [... this._listFieldsDropdown, {
      value: apiIntegrationEndpointFieldsMappingModel.voiceboxFieldName,
      label: apiIntegrationEndpointFieldsMappingModel.voiceboxDisplayName
    }];
    this._listFieldsDropdown.sort((a, b) => a.label < b.label ? - 1 : Number(a.label > b.label));
    this.addRemoveValidationApiFieldMapping();
  }

  sendMessageFolderSelected(event) {
    this.sendContactMessage.get('message').setValue(null);
  }

  notifyMessageFolderSelected(event) {
    this.notifyContactMessage.get('message').setValue(null);
  }

  notifyTriggerApiSelected() {
    this.selectedTriggerApi = this.sendViaApiForm.get('triggerApi').value;
    this.sendViaApiForm.get('apiSection').setValue(null);
    this.sendViaApiForm.get('location').setValue(null);
    this.sendViaApiForm.get('system').setValue(null);
    this.sendViaApiForm.get('listField').setValue(null);
    this.sendViaApiForm.get('apiIntegrationEndpoint').setValue(null);
    this.apiIntegrationEndpointFieldsMappings = [];
    this.populateApiSectionDropdown();
    if (this.apiIntegrations
      .find(i => i.name == this.selectedTriggerApi)
      .clientParameters
      .find(c => c.schemaId > 0) != null){
        this.populateDMSDropdown();
        this._showSendApiSystem = true;
        this._showSendApiLocation = false;
      }
      else{        
        this.populateLocationsDropdown();
        this._showSendApiLocation = true;
        this._showSendApiSystem = false;
      }
  }

  notifyApiSectionSelected() {
    this._showSendApiFieldsMapping = true;
    this.selectedApiSectionName = this.sendViaApiForm.get('apiSection').value;
    this.sendViaApiForm.get('listField').setValue(null);
    this.sendViaApiForm.get('apiIntegrationEndpoint').setValue(null);
    this.apiIntegrationEndpointFieldsMappings = [];
    this.populateApiIntegrationEndpointFieldsDropdown();
    this.populateFieldDropdown();
  }


  GetLightMessages(folderID: number, from: string) {
    var lightMessages: Array<LightweightMessageModel>;
    if (folderID) {
      if (from === 'send') {
        this.messageService.getLightMessages(folderID, this._currentSendMessageType).subscribe(result => {
          lightMessages = this.lightweightMessageModelAdapter.adaptArray(result.data);
          this.populateLightSendMessagesDropdown(lightMessages);
        }, error => {
          console.log(error);
        });
      } else {
        this.messageService.getLightMessages(folderID, this._currentNotifyMessageType).subscribe(result => {
          lightMessages = this.lightweightMessageModelAdapter.adaptArray(result.data);
          this.populateLightNotifyMessagesDropdown(lightMessages);
        }, error => {
          console.log(error);
        });
      }
    }
  }

  populateLightSendMessagesDropdown(lightMessages: Array<LightweightMessageModel>) {
    this._sendMessageDropdown = [];
    lightMessages.forEach((message: LightweightMessageModel) => {
      this._sendMessageDropdown = [... this._sendMessageDropdown, {
        value: message.MessageID,
        label: message.MessageName
      }];
    });
  }

  populateLightNotifyMessagesDropdown(lightMessages: Array<LightweightMessageModel>) {
    this._notifyMessageDropdown = [];
    lightMessages.forEach((message: LightweightMessageModel) => {
      this._notifyMessageDropdown = [... this._notifyMessageDropdown, {
        value: message.MessageID,
        label: message.MessageName
      }];
    });
  }

  addRemoveValidation() {
    this.sendContactMessage.get('message').setValidators(null);
    this.notifyContactMessage.get('message').setValidators(null);
    this.notifyContactMessage.get('contacts').setValidators(null);
    this.sendViaApiForm.get('triggerApi').setValidators(null);
    this.sendViaApiForm.get('system').setValidators(null);
    this.sendViaApiForm.get('location').setValidators(null);
    this.sendViaApiForm.get('apiSection').setValidators(null);
    this.sendViaApiForm.get('apiIntegrationEndpoint').setValidators(null);
    this.notifyContactUploadMessage.get('contacts').setValidators(null);

    if (this._showSendForm) {
      this.sendContactMessage.get('message').setValidators([Validators.required]);
    }

    if (this._showNotifyForm) {
      this.notifyContactMessage.get('message').setValidators([Validators.required]);
      this.notifyContactMessage.get('contacts').setValidators([Validators.required]);
    }

    if (this._showNotifyUploadForm) {
      this.notifyContactUploadMessage.get('contacts').setValidators([Validators.required]);
    }

    if (this._showSendViaApiForm) {
      this.sendViaApiForm.get('triggerApi').setValidators([Validators.required]);
      if(this._showSendApiSystem){
        this.sendViaApiForm.get('system').setValidators([Validators.required]);
      }
      if(this._showSendApiLocation){
        this.sendViaApiForm.get('location').setValidators([Validators.required]);
      }
      this.sendViaApiForm.get('apiSection').setValidators([Validators.required]);
      this.addRemoveValidationApiFieldMapping();
    }

    this.sendContactMessage.get('message').updateValueAndValidity();
    this.notifyContactMessage.get('message').updateValueAndValidity();
    this.notifyContactMessage.get('contacts').updateValueAndValidity();
    this.sendViaApiForm.get('triggerApi').updateValueAndValidity();
    this.sendViaApiForm.get('system').updateValueAndValidity();
    this.sendViaApiForm.get('location').updateValueAndValidity();
    this.sendViaApiForm.get('apiSection').updateValueAndValidity();
    this.notifyContactUploadMessage.get('contacts').updateValueAndValidity();

  }

  addRemoveValidationApiFieldMapping() {
    if (this._apiIntegrationEndpointFields != null
      && this._apiIntegrationEndpointFields.filter(a => a.mandatory).length > 0
      && (this.sendViaApiForm.get('apiIntegrationEndpoint').value == null
          || this.sendViaApiForm.get('listField').value == null
           || this._apiIntegrationEndpointFields
              .filter(a => a.value != this.sendViaApiForm.get('apiIntegrationEndpoint').value)
              .filter(a => a.mandatory).length > 0)) {
      this.sendViaApiForm.get('apiIntegrationEndpoint').setValidators(Validators.required);
      this.sendViaApiForm.get('apiIntegrationEndpoint').setErrors({ required: true });
      this.sendViaApiForm.get('apiIntegrationEndpoint').markAsDirty();
      this.sendViaApiForm.get('listField').setValidators(Validators.required);
      this.sendViaApiForm.get('listField').setErrors({ required: true });
      this.sendViaApiForm.get('listField').markAsDirty();
    }
    else {
      this.sendViaApiForm.get('apiIntegrationEndpoint').setValidators(null);
      this.sendViaApiForm.get('apiIntegrationEndpoint').updateValueAndValidity();
      this.sendViaApiForm.get('listField').setValidators(null);
      this.sendViaApiForm.get('listField').updateValueAndValidity();
    }
  }

  clearAll() {
    this.selectedContacts = [];
    this.selectedContactIds = [];
    this.selectContactsComponent._selectedContactsEmail = [];    
    this.notifyContactMessage.get('contacts').setValue(null);
  }

  clearAllContactsUpload() {
    this.selectedContactsUpload = [];
    this.selectedContactIdsUpload = [];
    this.selectContactsUploadComponent._selectedContactsEmail = [];
    this.notifyContactUploadMessage.get('contacts').setValue(null);
  }

  enableNotifyContactsUpload() {
    this._showNotifyContactsUpload = true;
  }

  disableNotifyContactsUpload() {
    this._showNotifyContactsUpload = false;
    this.clearAllContactsUpload();
  }

  showSelectedContactValues() {
    this._readableContactValues = [];

    const values = this.triggersService.fields.value;
    values.forEach(value => {
      const readableValue: Array<any> = [];
      if (value.displayName) {
        readableValue.push(value.displayName);
      }
      if (value.updateType) {
        switch (value.updateType) {
          case 'RawValue':
            readableValue.push('Set To');
            break;
          case 'FieldName':
            readableValue.push('Set To Field');
            break;
          case 'Reset':
            readableValue.push('Reset');
            break;
        }
      }

      let argument = value.argument ? value.argument : value.argumentFromEdit;
      const argumentDisplayValues = { 0: "No", 1: "Yes", };

      if (argument) {
        switch (typeof argument) {
          case 'string':
            argument = argumentDisplayValues[argument] || argument;
            readableValue.push(argument);
            break;
          case 'number':
            readableValue.push(argument);
            break;
          case 'object':
            if (typeof argument[0] === 'number') {
              readableValue.push(argument[3]);
            } else {
              argument.forEach(arg => {
                readableValue.push(arg);
              });
            }
        }
      }
      this._readableContactValues.push(readableValue);
    });
  }

  populateUpdateContactValues() {

    this.updateContactValues = [];
    this.triggersService.fields.value.forEach(value => {
      const contactValue = this.changeModelAdapter.createEmpty();
      contactValue.Name = value.name;
      contactValue.UpdateType = value.updateType;

      const argument = value.argument ? value.argument : value.argumentFromEdit;

      switch (typeof argument) {
        case 'string':
          contactValue.Argument = argument;
          break;
        case 'number':
          contactValue.Argument = argument.toString();
          break;
        case 'object':
          if (argument) {
            if (typeof argument[0] === 'number') {
              contactValue.Argument = argument[3];
            } else {
              var argumentString = '';

              argument.forEach((arg, index) => {
                if (index > 0) {
                  argumentString = argumentString.concat(',');
                }
                argumentString = argumentString.concat(arg);
              });
              contactValue.Argument = argumentString;
            }
          }
          break;
      }
      this.updateContactValues.push(contactValue);
    });
  }

  showNotifyContactsMessagePreviewModal() {
    //Check to see if the message type is Email or SMS to show diffirent previews
    this._messageTypeNum = this.actionsToPerformFormGroup.get('notifyContactMessage').get('messageType').value;

    if (this._messageTypeNum == 1) this.showMessagePreviewModal(this._notifyContactsSelectedMessageId);
    else this.showSMSPreviewModal(this._notifyContactsSelectedMessageId);
  }

  showSendContactsMessagePreviewModal() {
    this._messageTypeNum = this.actionsToPerformFormGroup.get('sendContactMessage').get('sendMessageType').value;

    if (this._messageTypeNum == 1 || this._messageTypeNum == 3) this.showMessagePreviewModal(this._sendContactsSelectedMessageId);
    else this.showSMSPreviewModal(this._sendContactsSelectedMessageId);
  }

  showSMSPreviewModal(messageId: number) {
    this.smsMessageService.get(messageId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._smsMessageContent = executionResultDto.data.SMSContent;
        this._smsMessageContentDate = executionResultDto.data.UpdatedDate;
        this._messagePreviewModal.show();
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  showMessagePreviewModal(messageId: number) {
    this.emailMessageService.getPreviewMessage(messageId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._messagePreviewHtml = executionResultDto.data.HtmlContent;
        this._messagePreviewModal.show();
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }
}
