<div class="row mt-4">
  <div class="col-md-6 offset-3">
    <!--Card-->
    <mdb-card cascade="true" narrower="true" class="mt-2">

      <!--Card Header-->
      <div class="view view-cascade gradient-card-header primary-color narrower
        py-2 mx-4 mb-3 d-flex justify-content-center">
        <h4 class="page-title">{{_titleText}}</h4>
      </div>
      <!--/Card Header-->

      <!--Card Content-->
      <mdb-card-body cascade="true" class="">

        <section>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-8 offset-2">
                <form [formGroup]="this.triggerDetailsForm">
                  <div class="md-form remove-bottom-margin">
                    <input 
                    mdbInput 
                    type="text" 
                    class="form-control"
                    id="trigger-title"
                    max="500"
                    formControlName="title">
                    <label for="trigger-title">Title</label>
                    <div class="alert alert-warning remove-padding" *ngIf="this.triggerDetailsForm.get('title').invalid">
                      Please enter a title.
                    </div>
                  </div>                  
                </form>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mdb-stepper #stepper [linear]="true" [vertical]="true" class="trigger-container">
                  <mdb-step name="This action relates to" [stepForm]="stepForm.controls.actionRelatesFormGroup">
                    <app-action-relates #actionRelatesComponent [actionRelatesFormGroup]="stepForm.controls.actionRelatesFormGroup" (listChanged)="onListChanged($event)" (messageChanged)="onMessageChanged()" (validationCheck)="showValidationError($event)">
                      
                    </app-action-relates>
                  </mdb-step>
                  <mdb-step name="Which will be triggered by" [stepForm]="stepForm.controls.triggerFormGroup">
                    <app-trigger #triggerComponent [triggerFormGroup]="stepForm.controls.triggerFormGroup" [actionRelatesFormGroup]="stepForm.controls.actionRelatesFormGroup" [timeBasedFormGroup]="stepForm.controls.triggerFormGroup" [timeBasedWCCFormGroup]="stepForm.controls.triggerFormGroup" [timeBasedOneOffSendFromGroup]="stepForm.controls.triggerFormGroup">
                      
                    </app-trigger>
                  </mdb-step>
                  <mdb-step name="And perform the following action(s)" [stepForm]="stepForm.controls.actionsToPerformFormGroup">
                    <app-actions-to-perform #actionsToPerformComponent [actionsToPerformFormGroup]="stepForm.controls.actionsToPerformFormGroup" [actionRelatesFormGroup]="stepForm.controls.actionRelatesFormGroup" [triggerFormGroup]="stepForm.controls.triggerFormGroup">
                      
                    </app-actions-to-perform>
                  </mdb-step>
                  <mdb-step *ngIf="this._showTimingStep" name="At this time" [stepForm]="stepForm.controls.timingFormGroup">
                    <app-timing [timingFormGroup]="stepForm.controls.timingFormGroup">
                      
                    </app-timing>
                  </mdb-step>
                  <mdb-step *ngIf="this._showABTestStep" name="Email (A/B Testing) actions" [stepForm]="stepForm.controls.abTestingFormGroup">
                    <form [formGroup] = "stepForm.controls.abTestingFormGroup">
                      <div class="container">
                        <div class="row">
                          <div class="col-md-10">
                            <label class="label-style-bold" for="triggerMessageSubjectA">Subject A</label>
                            <input type="text" id="triggerMessageSubjectA" class="form-control input-style" formControlName="triggerMessageSubjectA" readonly>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-10">
                            <label class="label-style-bold" for="triggerMessageSubjectB">Subject B</label>
                            <input type="text" id="triggerMessageSubjectB" class="form-control input-style" formControlName="triggerMessageSubjectB">
                          </div>
                        </div>
            
                        <div class="row margin-top-20">
                          <div class="col-md-auto">
                            <label class="label-style-bold">Test Metric</label>
                          </div>
          
                          <div class="col-md-auto">
                            <label class="label-style-regular">Open Rate</label>
                          </div>       
                        </div>
            
                        <div class="row margin-top-20">
                          <div class="col-md-auto">
                            <label class="label-style-bold">Test Length</label>
                          </div>
          
                          <div class="col-md-auto">
                            <input type="number" id="triggerAbTestLength" class="form-control input-style" formControlName="triggerAbTestLength" min="1" (ngModelChange)="onTriggerAbTestLengthChange($event)" onkeypress="return event.charCode > 48"/>
                          </div>
        
                          <div class="col-md-auto">
                            <select class="form-control select-input"
                                    name="triggerAbTestLengthType"
                                    id="triggerAbTestLengthType" 
                                    formControlName="triggerAbTestLengthType">
                              <option value=1>{{ _triggerAbTestLengthTypeHours }}</option>
                              <option value=2>{{ _triggerAbTestLengthTypeDays }}</option>
                            </select>
                          </div>
        
                          <div class="col-md-auto">
                            <label class="label-style-regular">We recommend you test for at least 4 hours</label>
                          </div>                         
                        </div>
            
                        <div class="row margin-top-20">
                          <div class="col-md-10">
                            <label class="label-style-bold">Contact List Test Size</label>
                          </div>
                        </div>

                        <div class="row">                          
                          <div class="col-md-10">                            
                            <mdb-range-input class="range-field" min="10" max="100" step="10" formControlName="abTestSize" (rangeValueChange)="onTriggerTestSizeValueChange($event)"></mdb-range-input>
                          </div>
        
                          <div class="col-md-2">
                            <label class="label-style-regular">{{_selectedTriggerABTestSize}}%</label> 
                          </div>                           
                        </div>
  
                        <div class="row">
                          <div class="col-md-auto">
                            <label class="label-style-regular">A Recipients {{_subjectACount}} ({{_subjectAPercent}}%), B Recipients {{_subjectBCount}} ({{_subjectBPercent}}%), Winner Recipients {{_winningCount}} ({{_winningPercent}}%)</label> 
                          </div>
                        </div>

                        <div class="row margin-top-20">
                          <div class="col-md-10">
                            <div class="alert alert-warning justify-content-center" role="alert" *ngIf="_showWrongSizeOrContactsCountWarning">
                              No contacts will be sent the A/B test message. Either increase the Test Size or add more recipients.
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </mdb-step>
                  <mdb-step *ngIf="this._showPreviewStep" name="Preview Data and Message for Send">
                    <app-preview-data-message #previewDataMessageComponent 
                      [parentStepForm]="stepForm"
                      [listID]="_selectedListID"
                      [filterContactRules]="_contactFilter"
                      [listFolder]="_selectedListFolder"
                      [messageID]="_selectedMessageID"
                    >
                    </app-preview-data-message>                   
                  </mdb-step>
                </mdb-stepper>

                <div class="row">
                  <div class="col-md-12 text-center">
                    <button type="button" mdbBtn color="primary" class="rounded" [disabled]="this.isAllDataValid()" (click)="submitTriggerForm()">Save</button>
                    <button type="button" mdbBtn color="primary" class="rounded" (click)="cancelClicked()">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </mdb-card-body>
      <!--/Card Content-->
    </mdb-card>
    <!--/Card-->
  </div>
</div>